function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useState, useEffect, useCallback } from "react";
import _api from "./api"; // Allows AuthForm to be controlled by the 'open' and 'close' events fired by the API.

var APIAuthFormAdapter = function APIAuthFormAdapter(_ref) {
  var _ref$api = _ref.api,
      api = _ref$api === void 0 ? _api : _ref$api,
      _formType = _ref.formType,
      _nextUrl = _ref.nextUrl,
      _preventSwitchFromSigninForm = _ref.preventSwitchFromSigninForm,
      _preventSwitchFromSignupForm = _ref.preventSwitchFromSignupForm,
      renderAuthForm = _ref.renderAuthForm,
      _signinFormBackButtonText = _ref.signinFormBackButtonText,
      _signinSubmitButtonText = _ref.signinSubmitButtonText,
      _ref$signupFormTitle = _ref.signupFormTitle,
      _signupFormTitle = _ref$signupFormTitle === void 0 ? "Create an Account" : _ref$signupFormTitle,
      _signupFormPasswordHelperText = _ref.signupFormPasswordHelperText,
      _signupSubmitButtonText = _ref.signupSubmitButtonText,
      _ref$skipConsents = _ref.skipConsents,
      _skipConsents = _ref$skipConsents === void 0 ? false : _ref$skipConsents;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      key = _useState4[0],
      setKey = _useState4[1];

  var _useState5 = useState(_formType),
      _useState6 = _slicedToArray(_useState5, 2),
      formType = _useState6[0],
      setFormType = _useState6[1];

  var _useState7 = useState(_nextUrl),
      _useState8 = _slicedToArray(_useState7, 2),
      nextUrl = _useState8[0],
      setNextUrl = _useState8[1];

  var _useState9 = useState(_preventSwitchFromSigninForm),
      _useState10 = _slicedToArray(_useState9, 2),
      preventSwitchFromSigninForm = _useState10[0],
      setPreventSwitchFromSigninForm = _useState10[1];

  var _useState11 = useState(_preventSwitchFromSignupForm),
      _useState12 = _slicedToArray(_useState11, 2),
      preventSwitchFromSignupForm = _useState12[0],
      setPreventSwitchFromSignupForm = _useState12[1];

  var _useState13 = useState(_signinFormBackButtonText),
      _useState14 = _slicedToArray(_useState13, 2),
      signinFormBackButtonText = _useState14[0],
      setSigninFormBackButtonText = _useState14[1];

  var _useState15 = useState(_signinSubmitButtonText),
      _useState16 = _slicedToArray(_useState15, 2),
      signinSubmitButtonText = _useState16[0],
      setSigninSubmitButtonText = _useState16[1];

  var _useState17 = useState(_signupSubmitButtonText),
      _useState18 = _slicedToArray(_useState17, 2),
      signupSubmitButtonText = _useState18[0],
      setSignupSubmitButtonTexts = _useState18[1];

  var _useState19 = useState(_signupFormTitle),
      _useState20 = _slicedToArray(_useState19, 2),
      signupFormTitle = _useState20[0],
      setSignupFormTitle = _useState20[1];

  var _useState21 = useState(_signupFormPasswordHelperText),
      _useState22 = _slicedToArray(_useState21, 2),
      signupFormPasswordHelperText = _useState22[0],
      setSignupFormPasswordHelperText = _useState22[1];

  var _useState23 = useState(_skipConsents),
      _useState24 = _slicedToArray(_useState23, 2),
      skipConsents = _useState24[0],
      setSkipConsents = _useState24[1];

  var openModal = useCallback(function (options) {
    setNextUrl(options.nextUrl);
    setFormType(options.formType);
    setPreventSwitchFromSigninForm(options.preventSwitchFromSigninForm);
    setPreventSwitchFromSignupForm(options.preventSwitchFromSignupForm);
    setSigninFormBackButtonText(options.signinFormBackButtonText);
    setSigninSubmitButtonText(options.signinSubmitButtonText);
    setSignupSubmitButtonTexts(options.signupSubmitButtonText);
    setSignupFormTitle(options.signupFormTitle);
    setSignupFormPasswordHelperText(options.signupFormPasswordHelperText);
    setSkipConsents(options.skipConsents); // Force the auth form to rerender when we open it
    // so that it always takes these as the latest settings
    // even if the auth form state has evolved since then.
    //
    // This is important, for example, to make sure the consumer
    // can always choose exactly which form to open.

    setKey(function (state) {
      return state + 1;
    });
    setIsOpen(true);
  }, []);
  var closeModal = useCallback(function () {
    setIsOpen(false);
    setNextUrl();
  }, []);
  useEffect(function () {
    api.on("open", openModal);
    api.on("close", closeModal);
    return function () {
      api.removeListener("open", openModal);
      api.removeListener("close", closeModal);
    };
  }, [openModal, closeModal]);
  return renderAuthForm({
    formType: formType,
    isOpen: isOpen,
    key: key,
    nextUrl: nextUrl,
    onClose: closeModal,
    preventSwitchFromSigninForm: preventSwitchFromSigninForm,
    preventSwitchFromSignupForm: preventSwitchFromSignupForm,
    signinFormBackButtonText: signinFormBackButtonText,
    signinSubmitButtonText: signinSubmitButtonText,
    signupFormTitle: signupFormTitle,
    signupFormPasswordHelperText: signupFormPasswordHelperText,
    signupSubmitButtonText: signupSubmitButtonText,
    skipConsents: skipConsents
  });
};

export default APIAuthFormAdapter;