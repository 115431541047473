/* global analytics */
function trackEvent() {
  var trackingEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var trackingProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var nextUrl = arguments.length > 2 ? arguments[2] : undefined;

  // If no tracking event is specified, don't track.
  var maybeGotoNextUrl = function maybeGotoNextUrl() {
    if (nextUrl) window.location.href = nextUrl;
  };

  if (trackingEvent !== "") {
    try {
      analytics.track(trackingEvent, trackingProps, {}, maybeGotoNextUrl); // backup if tracking takes an age

      setTimeout(maybeGotoNextUrl, 500);
    } catch (err) {
      console.error("Error tracking event", err);
      maybeGotoNextUrl();
    }
  } else {
    maybeGotoNextUrl();
  }
}

export { trackEvent }; // eslint-disable-line