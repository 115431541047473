const getUrlParameter = (name) => {
  // eslint-disable-next-line no-useless-escape
  const nameFixed = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${nameFixed}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results !== null ? decodeURIComponent(results[1].replace(/\+/g, " ")) : null;
};

// eslint-disable-next-line import/prefer-default-export
export { getUrlParameter };
