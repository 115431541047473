import MockAuthService from "../services/MockAuthService";
import MockConsentService from "../services/MockConsentService";
import SeekrAuthService from "../services/SeekrAuthService";
import SeekrConsentService from "../services/SeekrConsentService";
import AuthAPI from "./AuthAPI";
var authService = new SeekrAuthService();
var consentService = new SeekrConsentService();

if (process.env.REACT_APP_MOCK === "true") {
  console.log("Using Mock Services");
  authService = new MockAuthService();
  consentService = new MockConsentService();
}

var getOrCreateAuthAPISingleton = function getOrCreateAuthAPISingleton() {
  var _api;

  if (typeof window === "undefined") {
    _api = new AuthAPI(authService, consentService);
  } else if (typeof window.auth !== "undefined") {
    _api = window.auth;
  } else {
    _api = new AuthAPI(authService, consentService);

    _api.init();

    window.auth = _api;
  }

  return _api;
};

var api = getOrCreateAuthAPISingleton();
export { getOrCreateAuthAPISingleton };
export default api;