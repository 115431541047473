/* global document */

import { getOrCreateAuthAPISingleton } from "@muchbetteradventures/signup-form-lib";

import { getUrlParameter } from "./utils";


// Adds callbacks to auth trigger elements to open the modal via the global api
//
// The modal auth form must already be mounted and connected to the api's events
//
// eg
// <div class="auth-trigger" data-form-type="signup">Open Sign Up Modal</div>
// will have a callback added to the click event that will open the signup form modal on click

const _api = getOrCreateAuthAPISingleton();

window.addAuthTriggers = (selector, api = _api) => {
  // Custom links, buttons
  const authTriggers = document.querySelectorAll(selector);

  if (authTriggers) {
    const mount = (node) => {
      const nextUrl = node.href || node.dataset.nextUrl || getUrlParameter("next") || "/";
      const {
        formType,
        preventSwitchFromSigninForm,
        preventSwitchFromSignupForm,
        signinFormBackButtonText,
        signinSubmitButtonText,
        signupFormTitle,
        signupFormPasswordHelperText,
        signupSubmitButtonText,
        skipConsents,
      } = node.dataset;
      const trackingEvent = node.dataset.trackingEvent || "";
      const trackingProps = JSON.parse(node.dataset.trackingProps || "{}");

      node.addEventListener("click", (event) => {
        event.preventDefault();
        api.open({
          formType,
          nextUrl,
          preventSwitchFromSigninForm,
          preventSwitchFromSignupForm,
          signinFormBackButtonText,
          signinSubmitButtonText,
          signupFormTitle,
          signupFormPasswordHelperText,
          signupSubmitButtonText,
          skipConsents,
          trackingEvent,
          trackingProps,
        });
      });
    };

    // Can't call .map directly because querySelectorAll doesn't return with an array type
    [].map.call(authTriggers, mount);
  }
};

window.addAuthTriggers(".auth-trigger, a[data-auth-required]", _api);
