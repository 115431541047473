/* global document */
import React from "react";
import ReactDOM from "react-dom";

import { AuthFormRoot, APIAuthFormAdapter } from "@muchbetteradventures/signup-form-lib";

// Mounts the auth form modal ready to be opened/closed via the api.

const el = document.createElement("div");
document.body.appendChild(el);
ReactDOM.render(
  <APIAuthFormAdapter
    renderAuthForm={props => <AuthFormRoot {...props} />}
  />,
  el,
);
